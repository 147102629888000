import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Aboutus from '../Menabout/Aboutus'
import Testimonial from '../Menhomeone/Testimonial'
import Ctathree from '../Menabout/Ctathree'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="About Us" />
                <Aboutus />
                <Testimonial />
                <Ctathree />
                <Footer />
            </div>
        </>
    )
}

export default Main