import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import InformationQuality from '../MenserviceInformationQuality/InformationQuality'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Information Quality Management Services" />
                <InformationQuality />
                <Footer />
            </div>
        </>
    )
}

export default Main