import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Technologypage from '../Mentechnology/Technologypage';
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Technologies" />
                <Technologypage />
                <Footer />
            </div>

        </>
    )
}

export default Main