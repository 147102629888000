import React from 'react'
import { Link } from 'react-router-dom'
import Abtimg1 from '../../assets/images/resources/about-2-1.jpg'
import Abtimg2 from '../../assets/images/resources/about-2-2.jpg'
import Abtimg3 from '../../assets/images/resources/about-1-bulb.png'
import Abtshape from '../../assets/images/resources/about-1-shape-1.png'
import Abtauthor from '../../assets/images/resources/about-1-author.jpg'
import Abtsignin from '../../assets/images/resources/about-sign.png'

function Aboutus() {
    return (
        <>
            <section className="about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-two__image wow fadeInLeft" data-wow-delay="300ms">
                                <div className="about-two__image-one">
                                    <img src={Abtimg1} alt="leelaconsultancy" />
                                </div>
                                <div className="about-two__image-two">
                                    <img src={Abtimg2} alt="leelaconsultancy" />
                                    <div className="about-two__image-icon">
                                        <img src={Abtimg3} alt="leelaconsultancy"/>
                                    </div>
                                </div>
                                <img className="about-two__image__shape" src={Abtshape} alt="leelaconsultancy"/>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="about-two__content">
                                <div className="sec-title text-left">
                                    <h6 className="sec-title__tagline">about our Company</h6>
                                    <h3 className="sec-title__title">
                                    Solutions to Accelerate Your Business
                                    </h3>
                                </div>
                                <div className='about-two__content__text'>
                                <p>
                                    As a leader in digital transformation and optimisation, Leela Consultancy helps companies increase their clock speeds by utilising advanced information technologies and top-notch professionals. Using long-standing strategic partnerships with leading hyperscalers and digital technology experts, Leela Consulting has been offering services to customers. 
                                </p>
                                <p>Customers have benefited quickly from our expertise in Data, Cloud, Integration, and Process Automation, which is supported by tried-and-true systems and protocols. Our knowledge also includes application development solutions, where we create cutting-edge programmes customised to match the particular requirements of companies, guaranteeing smooth integration and improved operational effectiveness.
</p>
                                </div>
                                {/* <div className="about-two__content__info">
                                    <ul className="about-two__content__list">
                                        <li>
                                            <span className="fa fa-check-circle" />
                                            Hiring Employeers
                                        </li>
                                        <li>
                                            <span className="fa fa-check-circle" />
                                            Cost Efficiant
                                        </li>
                                        <li>
                                            <span className="fa fa-check-circle" />
                                            Outsourced Consulting
                                        </li>
                                        <li>
                                            <span className="fa fa-check-circle" />
                                            Leadership Training
                                        </li>
                                    </ul>
                                    <div className="clearfix" />
                                    <h5 className="about-two__content__title">
                                        <span className="icon-creative-idea" />
                                        Welcome to Professional Recruitment Agency
                                    </h5>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className='row mt-5 pt-lg-4'>
                        <div className='col-12'>
                            <div className="sec-title text-left pb-4">
                                <h3 className="sec-title__title">How are we different?</h3>
                            </div>
                            <p>At Leela Consultancy, we stand out by prioritizing a client-centric approach, deeply understanding each client's unique challenges, goals, and culture to propose tailored solutions. </p>
                            <p>Our broad range of services to boost your business includes Business Integration Strategy, Master Data Management, Data Warehousing, Information Quality Management, and Analytics & Performance Management. Our services have helped different businesses change their activities and have yielded valuable insights.</p>
                            <p className='mb-0'>Our wide knowledge allows us to offer complete insights and opinions, ensuring that our solutions are thoroughly researched and efficient. Our team is ready to offer innovative approaches that meet our clients' demands and go beyond their expectations, whether it is through process optimization, the implementation of new technology, or the solving of challenging problems.</p>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus