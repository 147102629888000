import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import Logo1 from '../../assets/images/logo-light.png'
import WOW from 'wowjs'

function Main() {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [companyDrop, setcompanyDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [projectsDrop, setprojectsDrop] = useState(false)
    const [blogDrop, setblogDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const activeMenu = () => {
        if (path === "/" || path === "/") {
            setmenu({ home: true })
        } else if (path === "/header" || path === "/header") {
            setmenu({ header: true })
        } else if (path === "/pages" || path === "/pages") {
            setmenu({ pages: true })
        } else if (path === "/projects" || path === "/projects") {
            setmenu({ projects: true })
        } else if (path === "/services" || path === "/services") {
            setmenu({ services: true })
        } else if (path === "/blog" || path === "/blog") {
            setmenu({ blog: true })


        }
    }


    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <>
            <div className="topbar-one">
                <div className="container-fluid">
                    <div className="topbar-one__inner">
                        <ul className="list-unstyled topbar-one__info">
                            <li className="topbar-one__info__item">
                                <i className="fas fa-envelope" />
                                <Link to="mailto:contact@leelaconsultancy.com">contact@leelaconsultancy.com</Link>
                            </li>
                            <li className="topbar-one__info__item">
                                <i className="fas fa-map-marker" />
                                <Link to="tel:+92(8800)-6930">1413 Stratford Ridge Ln, Cary, NC 27519, USA</Link>
                            </li>
                        </ul>
                        <div className="topbar-one__right">
                           <div className="topbar-one__social">
                                <Link to="https://twitter.com">
                                    <i className="fab fa-twitter" aria-hidden="true" />
                                    <span className="sr-only">Twitter</span>
                                </Link>
                                <Link to="https://facebook.com">
                                    <i className="fab fa-facebook" aria-hidden="true" />
                                    <span className="sr-only">Facebook</span>
                                </Link>
                                <Link to="https://pinterest.com">
                                    <i className="fab fa-pinterest-p" aria-hidden="true" />
                                    <span className="sr-only">Pinterest</span>
                                </Link>
                                <Link to="https://instagram.com">
                                    <i className="fab fa-instagram" aria-hidden="true" />
                                    <span className="sr-only">Instagram</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className={`main-header sticky-header sticky-header--normal ${sticky && "main-header sticky-header sticky-header--cloned active"}`}>
                <div className='container-fluid'>
                    <div className="main-header__inner">
                        <div className="main-header__logo">
                            <Link to="/">
                                <img src={Logo} alt="leelaconsultancy HTML" className='img-fluid'/>
                            </Link>
                        </div>
                        {/* <Link to="#" className="search-toggler main-header__search" onClick={() => setsearch(true)}>
                            <i className="icon-magnifying-glass" aria-hidden="true" />
                            <span className="sr-only">Search</span>
                        </Link> */}
                        <div className="main-header__menu">
                            <nav className="main-header__nav main-menu">
                                <ul className="main-menu__list">
                                    <li className={`dropdown ${menu.home && "current"}`}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className={`dropdown ${menu.pages && "current"}`}>
                                        <Link to="#">Company</Link>
                                        <ul>
                                            <li>
                                                <Link to="/about">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/our-sector">Our Sector</Link>
                                            </li>
                                            <li>
                                                <Link to="/staffing">Staffing</Link>
                                            </li>
                                            <li>
                                                <Link to="/clients">Clients</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li className={`dropdown ${menu.services && "current"}`}>
                                        <Link to="/services">Services</Link>
                                        <ul>
                                            <li>
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-master-data-management">Master Data Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-app-development">App Development</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <Link to="/technologies">Technologies</Link>
                                    </li>
                                    <li>
                                        <Link to="/careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                    
                                    
                                </ul>
                            </nav>
                            <div className="main-header__call">
                                <i className="icon-telephone" />
                                <Link to="tel:+9288006930">+1 919-655-9515</Link>
                            </div>
                        </div>
                        <div className="main-header__link">
                            <Link className="main-header__btn btn-primary" to="/contact">
                                Book Appointment
                            </Link>
                        </div>
                        <div className="mobile-nav__btn mobile-nav__toggler" onClick={toggleMobileMenu}>
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
            </header>

            {search &&
                <div className="search-popup active">
                    <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)} />
                    <div className="search-popup__content">
                        <form role="search" method="get" className="search-popup__form" action="#">
                            <input type="text" id="search" placeholder="Search Here..." />
                            <button type="submit"
                                aria-label="search submit" className="btn-primary btn-primary--base">
                                <span>
                                    <i className="icon-magnifying-glass" />
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            }

            {mobileMenuOpen &&
                <div className={`mobile-nav__wrapper ${mobileMenuOpen ? "expanded" : ""}`}>
                    <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setIsDropdownOpen(false)} />
                    <div className="mobile-nav__content">
                        <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setMobileMenuOpen(false)}>
                            <i className="fa fa-times" />
                        </span>
                        <div className="logo-box">
                            <Link to="/" aria-label="logo image" onClick={() => setIsDropdownOpen(false)}>
                                <img src={Logo} width={155} alt="" />
                            </Link>
                        </div>
                        <div className="mobile-nav__container">
                            <ul className="main-menu__list">
                                <li className="dropdown current">
                                    <Link to="/">Home</Link>
                                </li>
                                
                                <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link className={companyDrop ? "expanded" : ""}>Company<button aria-label="dropdown toggler" className={companyDrop ? "expanded" : ""} onClick={() => setcompanyDrop(companyDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                    {companyDrop &&
                                        <ul style={{ display: "block" }}>
                                            <li>
                                            <Link to="/about">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/our-sector">Our Sector</Link>
                                            </li>
                                            <li>
                                                <Link to="/staffing">Staffing</Link>
                                            </li>
                                            <li>
                                                <Link to="/clients">Clients</Link>
                                            </li>
                                        </ul>
                                    }
                                </li>
                                  
                                <li className="dropdown">
                                    <Link to="#" className={servicesDrop ? "expanded" : ""}>Services<button aria-label="dropdown toggler" className={servicesDrop ? "expanded" : ""} onClick={() => setservicesDrop(servicesDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                    {servicesDrop &&
                                        <ul style={{ display: "block" }}>
                                            <li>
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-master-data-management">Master Data Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-app-development">App Development</Link>
                                            </li>
                                            <li>
                                                <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                            </li>
                                        </ul>}
                                </li>
                                <li>
                                    <Link to="/technologies">Technologies</Link>
                                </li>
                                <li>
                                    <Link to="/careers">Careers</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                                
                            </ul>
                        </div>
                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                <i className="fa fa-envelope" />
                                <Link to="mailto:needhelp@leelaconsultancy.com">needhelp@leelaconsultancy.com</Link>
                            </li>
                            <li>
                                <i className="fa fa-phone-alt" />
                                <Link to="tel:666-888-0000">666 888 0000</Link>
                            </li>
                        </ul>
                        <div className="mobile-nav__social">
                            <Link to="https://twitter.com">
                                <i className="fab fa-twitter" aria-hidden="true" />
                                <span className="sr-only">Twitter</span>
                            </Link>
                            <Link to="https://facebook.com">
                                <i className="fab fa-facebook" aria-hidden="true" />
                                <span className="sr-only">Facebook</span>
                            </Link>
                            <Link to="https://pinterest.com">
                                <i className="fab fa-pinterest-p" aria-hidden="true" />
                                <span className="sr-only">Pinterest</span>
                            </Link>
                            <Link to="https://instagram.com">
                                <i className="fab fa-instagram" aria-hidden="true" />
                                <span className="sr-only">Instagram</span>
                            </Link>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Main