import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function InformationQuality() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Simplify Processes and Reduce Errors with Our Data Quality Management </h3>
                                <p>
                                Data quality management is a system to preserve data quality, which is defined by the supply, reliability, validity, precision, and completeness of the data within your company while fulfilling regulatory and legal requirements associated with a good or service. Businesses can analyse data, run marketing campaigns, and create trustworthy strategies much faster and more efficiently when they have access to high-quality data. Better return on investment and more focused advertising are always the outcomes of this.
                                </p>
                                <h3 className="service-details__lighlight pt-2 mb-3">
                                Our Data Quality Management Services Includes
                                </h3>
                                <h5>Uniqueness:</h5>
                                <p>We make sure that there is no duplicate information in the dataset, keeping precise and clean data. Removing duplicate information can save money and maintain brand reputation by avoiding the transfer of several marketing messages to the same contact. Our dedication to maintaining the uniqueness of our data improves the accuracy of analytics and making choices. </p>
                                <h5>Completion:</h5>
                                <p>We understand that completeness does not mean every field must be filled in, instead, it refers to giving appropriate information entries that are specific to the desired action, which is what we're trying to accomplish. For example, to fulfil its essential functions, an email marketing database would need to have all email addresses, yet, it wouldn't need phone numbers.</p>
                                <h5>Accuracy:</h5>
                                <p>Among the most crucial aspects of high-quality data is accuracy and we provide that. This has to do with how accurately the data reflects reality. For instance, it would be incorrect to use a zip code that fails to accurately represent the contact's address. For companies to make wise decisions, reliable information is essential. To make sure they have the best quality information accessible, incorrect information must be found, reported, and corrected. To make sure that messages target current customers and avoid errors, accurate data is crucial when it comes to marketing and advertising.</p>
                                
                                <div className="row gutter-y-30 align-items-center">
                                   
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title">Benefits of Data Quality Management</h4>
                                        
                                        <ul className="list-unstyled service-details__list mb-4">
                                            <li>
                                                <i className="fas fa-check-circle" /> Achieve customer satisfaction
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Higher Productivity Levels
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Boost Team confidence
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Reduced Business Cost
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className='mb-3'>Why Select Leela Consultancy for your  Data Quality Management? </h3>
                                <p>Leela Consultancy is the best data quality management service provider. Let us explore how:</p>
                                <p><strong className='text-secondary'>Enhanced Decision-Making:</strong> Well-researched choices rely on accurate and clean facts. At Leela Consultancy, we have an understanding of how important data is to the growth and success of businesses. We offer you a clean, reliable dataset for obtaining insights that can be placed into practice by meticulously cleaning and duplicating your data while maintaining its integrity.</p>
                                <p><strong className='text-secondary'>Compliance Assurance:</strong> To ensure that your data quality management processes not only match but also exceed industry regulations and standards, Leela Consultancy goes above and beyond. Our staff keeps updated on the most recent legal regulations to guarantee that your data handling procedures are fully compliant. Following such regulations helps to protect your company's integrity and reputation by minimising legal risks and potential penalties.</p>
                                <p><strong className='text-secondary'>Enhanced Operational Efficiency:</strong> At Leela Consultancy, we understand that streamlined corporate processes rely heavily on reliable and precise data. We help you in simplifying processes while getting rid of errors by making sure your data is error-free. Your team can work together more effectively, make decisions more rapidly, and generate better outcomes when you have accurate information at your fingertips.</p>
                                <p><strong className='text-secondary'>Saves Money: </strong> Clear and accurate information not only improves efficiency in operations but also saves a lot of money for your business. Duplicate and incorrect information can be eliminated to cut down on unnecessary expenses linked to customer communications, marketing efforts, and inefficiencies in operations. By using Leela Consultancy's data quality management services, you can more effectively handle your resources which will increase your company's profitability.</p>
                                <h4>Take Advantage of Clean Data by Working with Leela Consultancy</h4>
                                <p>Leela Consultancy is prepared to transform your company's data quality management procedures and take it to never-before-seen levels of success. With a comprehensive service, offering uniqueness, completion, and accuracy, we make sure that your data continues to be a valuable asset that promotes profitability and growth. You can count on us to make more informed choices, minimise paperwork, and cut costs without sacrificing principles or safety. Join up with Leela Consultancy now to begin harnessing the strength of clean data. Reach out to us to set up a consultation and unlock the complete power of the data you provide.</p>
                                <h5>Want to elevate your Business?</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Call Now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InformationQuality