import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function AnalyticsAndPerformance() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Maximise performance with Analytics and Performance Management</h3>
                                <p>
                                You have to be skilled at evaluating performance, encouraging advancement, and stimulating creativity inside your company. To what extent does your performance management plan include data analytics? We understand how an organisation may maximise both individual and organisational performance through performance management using data analytics. Through the integration of various approaches, such as profitability management, segmentation analytics, predictive analytics, business analytics, and enterprise intelligence, analytics-based performance management improves statistical analytics to new heights, allowing deeper insights from data that boost foresight and, eventually, decision-making. Analytics-based performance management offers a more extensive range of methods and instruments to the interested stakeholders, enabling them to collect and combine information from several different sources for a deeper look. It assists organisations to recognise "what can happen" along with providing the answer to "what happened" depending on the data.
                                </p>
                                <h3 className="service-details__lighlight pt-2 mb-3">
                                What does Leela Consultancy Provide you to Empower Success?
                                </h3>
                                <h5>Tailored Solutions</h5>
                                <p>We obtain an in-depth understanding of the goals, difficulties, and operations of the company. To find interaction points, data flows, and connections, we carry out an extensive process mapping exercise. We map the flow of goods from suppliers to customers, for instance, in a supply chain integration, keeping into account order fulfilment, managing inventories, and others.</p>
                                <h5>Advanced Analytics Capabilities</h5>
                                <p>We provide you with the use of cutting-edge analytics technologies and tools so that you can effectively utilise your data. We use innovative techniques to generate deeper insights and encourage well-informed decision-making, from machine learning to statistical analysis.</p>
                                <h5>Expert Advice</h5>
                                <p>With an abundance of expertise and experience, our team of data analytics experts are well-equipped to offer assistance. We offer expert guidance and support throughout the analytics process to help you solve challenging data circumstances and maximise the value of your data.</p>
                                <h5>Actual Insights</h5>
                                <p>Our analytics systems are meant to generate useful insights that result in quantifiable outcomes. We provide insights that enable you to make important choices, whether your goal is to improve customer satisfaction, maximise marketing efforts, or enhance efficiency in operations.</p>
                                <h5>Improvement</h5>
                                <p>We are experts in the process of continuous improvement. We help you stay one step ahead of the competition and foster ongoing achievement through data-driven choices by monitoring metrics for performance and continuously enhancing our analytics tactics.</p>
                                <div className="row gutter-y-30 align-items-center">
                                    
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title">Our performance management and analytics assist your company in many different ways</h4>
                                        
                                        <ul className="list-unstyled service-details__list mb-4">
                                            <li>
                                                <i className="fas fa-check-circle" /> Determine the operational performance metrics and strategic metrics, then optimise them for success.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Enable improved predictive accounting via budgets and rolling revenue projections, carry out possible analyses, and make more informed decisions regarding outsourcing.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Discover what procedures need to be modified.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Decide which investments will best minimise the risks you face.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Speed up management report complexity
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Strengthen control and accountability to achieve greater results.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> To get away from difficulty, discover and minimise difficult procedures.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Utilise continuous support for the shifting business atmosphere.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h4>Leverage Performance Management and Analytics Capabilities </h4>
                                <p>With the expert integration of analytics and performance management, the goal at Leela Consultancy is to boost your business toward unparalleled success. You are capable of making data-driven decisions that move your company ahead with our tailored offerings, advanced analytics capabilities, expert guidance, and commitment to ongoing development. Our comprehensive strategy guarantees that your company stays ahead of the competition in today's dynamic landscape, from improving operational and strategic metrics to enhancing predictive accounting and encouraging innovation. As a team, we will help you achieve new heights of success and improve your performance ability. </p>
                                <h5>Use analytics-driven solutions to transform your company</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Get in touch now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AnalyticsAndPerformance