import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Footer from '../Footer/Main'

import { Link } from 'react-router-dom';
import feature1 from '../../assets/images/resources/feature-1-1.jpg';
import feature2 from '../../assets/images/resources/feature-1-2.jpg';
import feature3 from '../../assets/images/resources/feature-1-3.jpg';

const features = [
    {
        img: feature1,
        iconClass: 'icon-solution',
        title: 'Health Care',
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'Retail',
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Financial',
    },
    {
        img: feature1,
        iconClass: 'icon-solution',
        title: 'Banking',
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'Manufacturing',
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Insurance',
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'IT',
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Consulting Services',
    }
];


function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Our Sector" />
                <section className='section-ptb'>
                    <div className='container'>
                        <div className='col-12'>
                            <div className="sec-title text-left pb-4">
                                <h3 className="sec-title__title">Our Sector</h3>
                            </div>
                            <p>Leela Consultancy’s solutions have helped companies that operate within a variety of markets - and we understand the issues that drive them. Leela Consultancy’s services are offered on a project or strategic staffing basis, in both custom and Data Warehousing as well as Data Integration package environments, and across all technology platforms, operating systems and infrastructures. When your business partners with us, we bring to the table depth of experience and solid credentials in each of the vertical markets below.</p>
                            <p>Leela Consultancy recognizes that to deliver solutions that meet your organization's unique needs, technical proficiency alone just isn't enough. So we focus on a select range of industries, and we bring you not only tailored technology solutions, but deep industry expertise and our commitment to a long-term relationship that helps you achieve and sustain measurable results.</p>
                            <p>We have experience with the following sectors:</p>
                        </div>
                        <div className="row gutter-y-30 mt-3">
                            {features.map((feature, index) => (
                                <div key={index} className="col-xl-4 col-lg-6 col-12 wow fadeInUp" data-wow-delay={`${100 * (index + 1)}ms`}>
                                    <div className="feature-one__item">
                                        <div className="feature-one__item__img">
                                            <img src={feature.img} alt="leelaconsultancy" />
                                        </div>
                                        <div className="feature-one__item__content">
                                            <div className="feature-one__item__ball" />
                                            <div className="feature-one__item__icon">
                                                <span className={feature.iconClass} />
                                            </div>
                                            <h3 className="feature-one__item__title">
                                                {feature.title}
                                            </h3>
                                            {/* <p className="feature-one__item__text">
                                                {feature.text}
                                            </p>
                                            <Link to="/about" className="btn-primary">
                                                <span>Find Your Solution</span>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main