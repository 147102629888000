import React from 'react'
import { Route, Routes, } from 'react-router-dom';
import Homeone from '../components/Homeone/Main';
import About from '../components/About/Main';
import OurSector from '../components/OurSector/Main'
import Staffing from '../components/Staffing/Main'
import Clients from '../components/Clients/Main'
import Careers from '../components/Careers/Main'
import Services from '../components/Services/Main'
import ServiceBusinessIntegration from '../components/Service-BusinessIntegration/Main'
import ServiceInformationQuality from '../components/Service-InformationQuality/Main'
import ServiceDataWarehousing from '../components/Service-DataWarehousing/Main'
import ServiceMasterDataManagement from '../components/Service-MasterDataManagement/Main'
import ServiceAnalyticsAndPerformance from '../components/Service-AnalyticsAndPerformance/Main'
import ServiceAppDevelopment from '../components/Service-AppDevelopment/Main'
import ServiceEnterpriseAppDevelopment from '../components/Service-EnterpriseAppDevelopment/Main'
import Technologies from '../components/Technologies/Main'

import Contact from '../components/Contact/Main';

function index() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Homeone />} />
                <Route path="/about" element={<About />} />
                <Route path="/our-sector" element={<OurSector />} />
                <Route path="/staffing" element={<Staffing />} />
                <Route path="/Clients" element={<Clients />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/services" element={<Services />} />
                <Route path="/service-business-integration-strategy" element={<ServiceBusinessIntegration />} />
                <Route path="/service-information-quality-management" element={<ServiceInformationQuality />} />
                <Route path="/service-data-warehousing" element={<ServiceDataWarehousing />} />
                <Route path="/service-master-data-management" element={<ServiceMasterDataManagement />} />
                <Route path="/service-analytics-and-performance-management" element={<ServiceAnalyticsAndPerformance />} />
                <Route path="/service-app-development" element={<ServiceAppDevelopment />} />
                <Route path="/service-Enterprise-app-development" element={<ServiceEnterpriseAppDevelopment />} />
                <Route path="/technologies" element={<Technologies />} />
                
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </>
    )
}

export default index