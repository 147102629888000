import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function BusinessIntegration() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Take Your Business to the Next Level with Professional Business Integration Strategy Services</h3>
                                <p>
                                Integration of businesses has become more and more important with changing times. Organisations that aim to be successful, versatile, and competitive have to make sure that all of their systems, employees, procedures, and functions are flawlessly aligned. Businesses significantly rely on partnerships with other companies in the dynamic online ecosystem to achieve their operational goals. Maintaining these partnerships requires not just value but also up-to-date effective communication. In the digital surroundings, business integration strategies have become essential for implementing automation and streamlining procedures, which in turn results in more income and productivity.
                                </p>
                                <h3 className="service-details__lighlight pt-2 mb-3">
                                    Our Business Integration Strategy Process
                                </h3>
                                <h5>Identify the Business context</h5>
                                <p>We obtain an in-depth understanding of the goals, difficulties, and operations of the company. To find interaction points, data flows, and connections, we carry out an extensive process mapping exercise. We map the flow of goods from suppliers to customers, for instance, in a supply chain integration, keeping into account order fulfilment, managing inventories, and others.</p>
                                <h5>Selecting Appropriate Integration Patterns</h5>
                                <p>The selection of integration patterns depends on what your business requires. We thoroughly analyse and select the best integration patterns per the specific needs of your company. With the help of our expertise, we allow your digital ecosystem to run effectively, encouraging efficient procedures and increasing output. Trust us to assist you in selecting the integration methods that will most effectively grow your company.</p>
                                <h5>Give security and compliance top priority</h5>
                                <p>When it comes to integration, security is our foremost priority. We take unique measures to safeguard sensitive data, securing it both in transit and storage. We additionally put robust role-based access controls into effect to avoid unauthorised access and ensure adherence to company standards and laws. You might feel safe knowing that your data will be safeguarded by our strong safety protocols.</p>
                                <h5>Track and optimise</h5>
                                <p>Being Business Integration Strategy experts, our approach is continuous optimisation and monitoring. To achieve optimum effectiveness and productivity, we closely track system performance, identify areas of concern, and modify processes. We make every attempt to keep your integration solutions functioning efficiently while offering your company the greatest value achievable with proactive monitoring and ongoing optimisation efforts.</p>
                                
                                <div className="row gutter-y-30 align-items-center">
                                    
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title">The benefits of Business Integration Strategies</h4>
                                        
                                        <ul className="list-unstyled service-details__list mb-4">
                                            <li>
                                                <i className="fas fa-check-circle" /> Improved communication
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Reduced Cost
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Stronger security
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Streamlined Operations
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Comprehensive Visibility
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Organized Workflows
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className='mb-3'>Why Should You Select Leela Consultancy For Your Business Integration Needs? </h3>
                                <p><strong className='text-secondary'>Flexibility and Scalability:</strong> Our business integration strategies are designed to expand with your company and adapt to meet changing demands. Leela Consultancy offers scalable and flexible solutions that develop with your company, whatever your size—from startups to large organisations.</p>
                                <p><strong className='text-secondary'>Committed support:</strong> Leela Consultancy is here to assist you at every turn with continuous assistance. Our team of professionals are here to assist you with business integration operations by providing solutions, and guidance, and handling any concerns you may have.</p>
                                <p><strong className='text-secondary'>Dedication to Excellence:</strong> At Leela Consultancy, we commit to performing everything with excellence. Our goal is to surpass what you expect and provide exceptional value to your company by always being creative and improving. You understand that your business integration needs are in capable hands if you partner with Leela Consultancy.</p>
                                <p><strong className='text-secondary'>Compliance Assurance:</strong> We ensure that the rules and guidelines controlling the industry are strictly adhered to by our business integration solutions. With our assistance in handling complex regulatory requirements, Leela Consultancy gives you peace of mind and ensures that your business always remains safe.</p>
                                <h4>Trust Leela Consultancy for the Best Business Integration Strategy Services </h4>
                                <p>At Leela Consultancy, we are well aware of how crucial seamless business integration is in the fast-paced world of today. We help businesses achieve their operational objectives and thrive in the digital age with our tailored strategy, focus on security and compliance, and commitment to quality. Your business integration requirements will be tackled and possibly surpassed with our flexible and scalable solutions, dedicated support team, and ongoing commitment to safety assurance. Join up with Leela Consultancy right now to confidently grow your company.</p>
                                <h5>Want to elevate your Business?</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Call Now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BusinessIntegration