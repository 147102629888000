import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Footer from '../Footer/Main'

function Main() {

    const careerData = [
        {
            listItem: [
              { list: 'PRA Health Sciences' },
              { list: 'PetroChoice Holdings, Inc.' },
              { list: 'Modern Woodmen of America' },
              { list: 'Cigna HealthSpring' },
              { list: 'Lennar Corporation' },
              { list: 'EMD Serono' },
              { list: 'Press Ganey Associates' },
              { list: 'Bausch & Lomb' },
              { list: 'Valeant Pharmaceutical' },
              { list: 'Salix Pharmaceutical' },
              { list: 'Qualcomm' },
              { list: 'Blue Cross & Blue Shield of Rhode Island' },
              { list: 'Service Corporation International' },
              { list: 'Sharp Corporation' },
              { list: 'XL Insurance' },
              { list: 'The Hartford Insurance' },
              { list: 'American Family Insurance' },
              { list: 'CVS Caremark' },
            ],
        },

        
    ];

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Clients" />
                <section className='section-ptb'>
                    <div className='container'>
                        <div className='col-12'>
                            <div className="sec-title text-left pb-4">
                                <h3 className="sec-title__title">Clients</h3>
                            </div>
                        </div>
                        <div className='col-12'>
                            {careerData.map((item) => (
                                <ul className="list list-unstyled mt-3 mb-4">
                                    {item.listItem.map((address, index) => (
                                        <li key={index} className='text-secondary mb-2'>
                                            <span className="fa fa-check-circle text-primary me-2" />
                                            {address.list}
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main