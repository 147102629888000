import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function DataWarehousing() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Utilise Our Data Warehousing Services to Manage Information Which is Essential to Your Business</h3>
                                <p>
                                We believe that utilising data to support your decisions may enhance your outcomes. Data is one of the most important resources your company possesses when it grows and flourishes. No matter how much you are sure about your data warehousing, you can always be certain it is precise and consistent with our innovative solution without sacrificing performance. If you want to ensure that mistakes are quickly detected and fixed before your consumers ever notice, we use real-time protection utilising the safest method that is available. We also interact with the APIs of the most well-known businesses currently present on the market. Our wide range of data warehouse services can help you grow your data warehouse and fully use the information it contains. 
                                </p>
                                <h3 className="service-details__lighlight pt-2 mb-3">
                                Accelerate Business Outcomes with Our Experienced Data Warehouse Services 
                                </h3>
                                <h5>Data Warehouse Consulting</h5>
                                <p>For better efficiency, are you experiencing difficulty choosing whether to upgrade your current information, store or create a new one? If the answer is yes, you have reached the right spot. Our professionals can guide data warehouse architecture and design, analyse current infrastructures, suggest the best alternative like cloud or hybrid data warehousing, develop integration plans, and suggest the most suitable technologies.</p>
                                <h5>Data Warehouse Development</h5>
                                <p>We excel in creating specialised data warehousing solutions developed to meet your company's unique data consolidation and storage requirements. Our all-inclusive methodology, including platform selection, architecture design, system analysis, requirements engineering, and conceptualisation covers every step of the implementation process. With proficiency in quality control, design, development, post-launch support, and data modelling, our dedicated employees ensure a seamless integration of your data warehouse into the ecosystem you operate.</p>
                                <h5>Data Warehouse Migration</h5>
                                <p>Consider that your data is kept on cloud servers. Wouldn't that be wonderful? You can choose the most suitable plan for yourself with the help of Leela Consultancy. Choosing whether to use a data warehouse or transfer your data might be supported by us. From establishing your data warehouse and testing the process to selecting the best cloud vendor, deploying cloud clusters, and designing a migration plan, we can help you with it all.</p>
                                <h5>Data Warehouse Support</h5>
                                <p>We provide full governance and administration assistance for your data warehouse, ensuring smooth management and operation. Our team of professionals are dedicated to meeting your needs as quickly and efficiently as possible, managing everything from capacity and administration of storage to resolution of tickets and immediate assistance. For maximum effectiveness and dependability, we emphasize job monitoring while making use of tried-and-true data warehouse processes. This provides you with the confidence of a solid and clean data infrastructure. </p>
                                
                                <div className="row gutter-y-30 align-items-center">
                                    
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title">What advantages do our data warehousing solutions provide?</h4>
                                         <ul className="list-unstyled service-details__list mb-4">
                                            <li>
                                                <i className="fas fa-check-circle" /> Streamline a majority of resource-intensive handling of data tasks and reduce the amount of time for insights.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Incorporate diverse datasets, both structured and unstructured, into one central database.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Provide accessibility to data while eliminating the likelihood of security breaches and cases of data misuse.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Obtain an in-depth understanding of your company's situation to assist you make wise decisions at every stage.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Make data mining, statistical data analysis, forecasting, forecasting, and what-if scenario planning possible.
                                            </li>
                                            <li>
                                                <i className="fas fa-check-circle" /> Give fast, accurate, consistent, and complete information to support your decisions.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h4>Leela Consultancy Provides the Best Data Warehouse Services  </h4>
                                <p>We enable companies with revolutionary approaches driven by cutting-edge technology and an unwavering commitment to quality. We offer pre-built, scalable components to drastically cut down on the time and cost involved in deploying databases and data warehouses. Leela Consultancy is a pioneer leader in reducing lengthy tasks into a matter of months. By minimising the dangers and complexities usually involved in such undertakings and guaranteeing a seamless and economical process, Leela Consultancy helps you reach your information objectives effectively and economically.</p>
                                <h5>Ready to work with our expert team?</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Get Started</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DataWarehousing