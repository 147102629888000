import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Staffing" />
                <section className='section-ptb'>
                    <div className='container'>
                        <div className='col-12'>
                            <div className="sec-title text-left">
                                <h3 className="sec-title__title">Staffing</h3>
                            </div>
                        </div>
                        <div className='col-12'>
                            <h4 className='mb-3'>Our Story</h4>
                            <p>Inspired by a passion for quality and a commitment to offering exceptional service, Leela Consultancy was established to transform the staffing industry. Our company, which was founded around 2016, has grown into a symbol of trust and innovation in the fields of information technology, accounting, sales recruitment, and healthcare personnel. We have proven ourselves as a reliable partner for exceptional professionals and industry-leading corporations because of our dedicated team of experts and constant focus on establishing lasting relationships. We offer the best placement at the time, our story at Leela Consultancy has been defined by a constant commitment to exceeding standards and an uncompromising dedication to excellence.</p>
                            <h4 className='mb-3 pt-2'>The Reason We Started</h4>
                            <p>In an attempt to meet the immediate need for an outstanding, consultative, and value-oriented strategy for healthcare staffing and recruitment, Leela Consultancy was established. We developed a recruiting agency that put relationships and quality always at top priority after understanding the difficulties that both job seekers and employers faced during emergencies. Our mission was to be a trustworthy partner for businesses looking to hire outstanding individuals and to deliver professionals seeking their next challenge and satisfying opportunities for their careers. Our objective was to improve the staffing sector and enable businesses to prosper in an ever-evolving marketplace by combining our experience with an extensive understanding of our client's requirements.</p>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main