import React from 'react'
import { Link } from 'react-router-dom'

function Ctafour() {
    return (
        <>
            <section className="cta-foue section-sm-ptb background-black">
                <div className="container">
                    <div className=''>
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h2 className="cta-foue__title">
                                    Hire Your Next Candidate on <span className='text-primary'>Leelaconsultancy</span>
                                </h2>
                            </div>
                            <div className="col-md-4 mt-4 mt-md-0 text-md-end">
                                <Link to="/about" className="btn btn-primary">
                                    <span>Discover More</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ctafour