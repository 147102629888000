import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function AppDevelopment() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Best Mobile Application Development Company That Brings Exceptional Ideas to Life</h3>
                                <p>
                                An expert mobile app development company, Leela Consultancy is driven by excellence, imagination, and uniqueness. On the Windows, iOS, and Android platforms, we provide mobile app development services. Our team enjoys new tools and technology. With lightning-fast speed, we can adapt to any technology or tool and give our clients the finest mobile app development services available. We create apps that are responsive enough to adjust to changing market trends for a range of mobile gadgets and devices.
                                </p>
                                <h3 className="service-details__lighlight pt-2 mb-3">
                                Our Mission is to Develop and Provide Secure Mobile Apps for iPhone and Android
                                </h3>
                                <h5>iPhone App Development</h5>
                                <p>If your firm is targeting the iPhone and iPad app market, then Leela Consultancy can enable you to design highly innovative iOS mobile apps suited to your business needs. For any kind of iOS mobile app development service, we provide end-to-end solutions. We have an excellent deal of specialisation in creating apps that work effectively with other apps from other developers. With a better user experience, our special solutions are simply incorporated into the GPS and camera services of various mobile devices. Because our skilled staff of mobile app developers is well-versed in the iOS platform, the apps they develop are compatible with all of its capabilities and user interfaces. We additionally help companies improve profitability and productivity with focused corporate apps.</p>
                                <h5>Android App Development</h5>
                                <p>The most popular phones on the market, with plenty of users, are Android models. For businessmen, there is an excellent opportunity to capitalise on this expanding industry. For you to meet the company's current and future needs, we develop progressive Android mobile applications. We ensure that your Android app provides an effortless user interface by testing it frequently. To boost app development creativity, our in-house staff of Android mobile app developers fully comprehend the market and develop apps that make use of the platform's distinctive characteristics. Our enterprise mobile apps are designed to be fully compatible with the current systems you have and to significantly simplify your business operations, allowing you to go mobile.</p>
                                <div className="row gutter-y-30 align-items-center">
                                    
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title">Why Should You Hire Leela Consultancy for Your USA Mobile App Development Project?</h4>
                                        <h5>Superb Designs</h5>
                                        <p>Before developing an app, Leela Consultancy's outstanding design team conducts extensive research and knows how to give users an amazing user experience. We make sure to give our users the greatest mobile app available in the USA so they may establish a strong global brand.</p>
                                        <h5>Committed Group</h5>
                                        <p>The most amazing mobile app development team at Leela Consultancy have the blessing to contribute their all to every project. We have rendered services to many small, medium, and big businesses. Our top mobile app developers in the USA are renowned for their capacity to offer outstanding solutions for all kinds of businesses.</p>
                                        <h5>Modest Development</h5>
                                        <p>The top approaches for developing mobile apps include flexible behaviour. Mobile app development companies can execute projects quickly and effectively, thanks to our agile methodology. For the project to be finished on schedule and within budget, Leela Consultancy uses an agile methodology.</p>
                                        <h5>Maximum Transparency</h5>
                                        <p>In most cases, mobile app development companies don’t stay clear of providing transparency. On the other hand, Leela Consultancy is dedicated to full transparency. You will be involved in all that we achieve. The mobile app development project is your own, so we don't hide anything.</p>
                                    </div>
                                </div>
                                <h4 className="service-details_list-title mb-2">Our Offerings</h4>
                                <p>For application development in the USA, Leela Consultancy is an established company. We offer a variety of services under just one roof. Our developers are highly skilled at delivering end-to-end solutions, whether it's creating a fresh application or updating a previous version. </p>
                                <h5>Develop Native Apps</h5>
                                <p>Our company is one of the top developers of apps for iPhone and Android. It doesn't require our developers much to develop native apps. Users can download these apps from their app stores. They have been developed specifically for a certain operating system.</p>
                                <h5>Cross-Platform Application Development</h5>
                                <p>Our area of expertise is developing cross-platform apps. The development of cross-platform applications that operate seamlessly across platforms is our team's expertise. With a single codebase, these apps have been built to function flawlessly across all platform types.</p>
                                <h5>Development of Progressive Web Applications</h5>
                                <p>The most sought-after place to create progressive web apps is Leela Consultancy. We create scalable PWAs. You can use your web browser to gain access to every feature of a native app because PWAs look native and use web browser APIs.</p>
                                <h5>Building of Enterprise Applications</h5>
                                <p>The team at our application development company has mastered the complex method for developing enterprise applications. We initially understand the vital requirements of your company before building apps that increase productivity and improve your workflow.</p>
                                <h5>Development of Wearable Apps</h5>
                                <p>Our wearable app development team at Leela Consultancy has unparalleled expertise, an abundance of experience, and in-depth understanding. They are the best option if you want to develop useful, attractive, and entertaining apps for wearables like smartwatches and eyewear.</p>
                                <h5>Solutions for App Maintenance</h5>
                                <p>In addition to building the software, we may assist you with its maintenance. Our team of skilled maintenance and support professionals is committed to delivering complete client satisfaction. Therefore, you can count on us to solve your difficulties and provide you with timely responses.</p>

                                <h5>Want a perfect app?</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Connect</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppDevelopment