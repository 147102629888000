import React from 'react'
import { Link } from 'react-router-dom';
import Techno1 from '../../assets/images/resources/techno-1.png'
import Techno2 from '../../assets/images/resources/techno-2.png'
import Techno3 from '../../assets/images/resources/techno-3.png'
import Techno4 from '../../assets/images/resources/techno-4.png'
import Techno5 from '../../assets/images/resources/techno-5.png'
import Techno6 from '../../assets/images/resources/techno-6.png'
import Techno7 from '../../assets/images/resources/techno-7.png'
import Techno8 from '../../assets/images/resources/techno-8.png'

const technologies = [
    {
        img: Techno1,
        iconClass: 'icon-solution',
        title: 'Health Care',
    },
    {
        img: Techno2,
        iconClass: 'icon-creative-idea',
        title: 'Retail',
    },
    {
        img: Techno3,
        iconClass: 'icon-mission',
        title: 'Financial',
    },
    {
        img: Techno4,
        iconClass: 'icon-solution',
        title: 'Banking',
    },
    {
        img: Techno5,
        iconClass: 'icon-creative-idea',
        title: 'Manufacturing',
    },
    {
        img: Techno6,
        iconClass: 'icon-mission',
        title: 'Insurance',
    },
    {
        img: Techno7,
        iconClass: 'icon-creative-idea',
        title: 'Manufacturing',
    },
    {
        img: Techno8,
        iconClass: 'icon-mission',
        title: 'Insurance',
    }
];


function Technologies() {
    return (
        <div>
            <div className="technologies-logo-wrapper section-ptb">
                <div className="container">
                    <div className="sec-title text-left">
                        <h6 className="sec-title__tagline">Technologies</h6>
                        <h3 className="sec-title__title">Our Technologies</h3>
                    </div>
                    <div className="row">
                        {technologies.map((technology, index) => (
                            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 wow fadeInUp" data-wow-delay={`${100 * (index + 1)}ms`}>
                                <div className="technologies-logo">
                                    <img src={technology.img} alt="leelaconsultancy" className='img-fluid'/>
                                </div>
                            </div>
                        ))}

                        <div className='row mt-4'>
                            <div className='col-12 text-center'>
                                <Link to="/technologies" className="btn-primary">
                                    <span>View All</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technologies