import React from 'react'

function Map() {
    return (
        <div>
            <section className="contact-map">
                <div className="container">
                    <div className="google-map google-map__contact">
                        <iframe
                            title="template google map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.093158308806!2d-78.92348481856762!3d35.79764625281007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89acec7cbed0304f%3A0x4dfc6207cea34569!2s1413%20Stratford%20Ridge%20Ln%2C%20Cary%2C%20NC%2027519%2C%20USA!5e0!3m2!1sen!2sin!4v1711377405486!5m2!1sen!2sin"
                            className="map__contact"
                            allowFullScreen=""
                        />
                    </div>
                    {/* /.google-map */}
                </div>
                {/* /.container-fluid */}
            </section>

        </div>
    )
}

export default Map