import React from 'react';
import { Link } from 'react-router-dom';
// import Service1 from '../../assets/images/services/sv-1.jpg';
// import Service2 from '../../assets/images/services/sv-2.jpg';
// import Service3 from '../../assets/images/services/sv-3.jpg';
// import Service4 from '../../assets/images/services/sv-4.jpg';
// import Service5 from '../../assets/images/services/sv-5.jpg';
// import Service6 from '../../assets/images/services/sv-6.jpg';

const services = [
    {
        number: "01",
        icon: "icon-consulting",
        category: "Management",
        title: "Business Integration Strategy",
        link: "/service-business-integration-strategy",
    },
    {
        number: "02",
        icon: "icon-controller",
        category: "Resources",
        title: "Information Quality Management Services",
        link: "/service-information-quality-management",
    },
    {
        number: "03",
        icon: "icon-policy",
        category: "Solution",
        title: "Data Warehousing Services",
        link: "/service-data-warehousing",
    },
    {
        number: "04",
        icon: "icon-consulting",
        category: "Management",
        title: "Master Data Management",
        link: "/service-master-data-management",
    },
    {
        number: "05",
        icon: "icon-controller",
        category: "Resources",
        title: "Analytics and Performance Management",
        link: "/service-analytics-and-performance-management",
    },
    {
        number: "06",
        icon: "icon-controller",
        category: "Resources",
        title: "App Development",
        link: "/service-app-development",
    },
    {
        number: "07",
        icon: "icon-controller",
        category: "Resources",
        title: "Enterprise App Development",
        link: "/service-enterprise-app-development",
    },
];

function Service() {
    return (
        <div>
            <section className="service-one background-gray section-ptb">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-12'>
                            <div className="sec-title text-left pb-4">
                                <h3 className="sec-title__title">
                                    Services We’re Providing
                                </h3>
                            </div>
                            <div className='pb-4'>
                                <p>Our team at Leela Consultancy takes great satisfaction in providing a wide range of services that are customised to meet the specific needs of modern organisations. Our method towards business integration is designed to improve efficiency, optimise operations, and drive expansion. We help businesses achieve seamless integration across an array of systems and procedures by means of careful planning and execution.</p>
                                <p>Our strength comes in developing personalised plans that seamlessly include many aspects of your business, such as streamlining operations and boosting productivity to guarantee ongoing success. We always make sure that the data you get in your hands is accessible, reliable, and accurate and provides you with better decision-making to drive business growth. We are also experienced in providing perfect data warehousing solutions. </p>
                                <p>We centralize your data using the latest technology and organise it for easy access and analysis. To maintain data integrity while offering better business insights, we help businesses preserve accurate and up-to-date master data across platforms. Our analytics solutions help companies to more effectively utilise their data, discover insights that may be put to use, and make choices and performances that are always evolving.</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row gutter-y-30">
                        {services.map((service, index) => (
                            <div key={index} className="col-xl-6 col-lg-12">
                                <div className="service-one-card wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index}ms`}>
                                    {/* <div className="service-one-card__image">
                                        <img src={service.image} alt={service.title} />
                                    </div> */}
                                        <span class="number">{service.number}</span>
                                        <div className="service-one-card__icon">
                                            <i className={service.icon} />
                                            {/* <Link to="/services" className="service-one-card__cat">
                                                {service.category}
                                            </Link> */}
                                        </div>
                                        <h3 className="service-one-card__title">
                                            <Link to={service.link}>{service.title}</Link>
                                        </h3>
                                        {/* <p className="service-one-card__text">
                                            {service.text}
                                        </p> */}
                                        <Link to={service.link} className="service-one-card__link">
                                            <i className="fas fa-arrow-right" />
                                        </Link>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </section>
        </div>
    );
}

export default Service;
