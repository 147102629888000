import React from 'react'
import { Link } from 'react-router-dom'
import Ctaimg from '../../assets/images/backgrounds/banner-bg-1-1.jpg'

function Ctathree() {
    return (
        <>
            <section className='section-ptb'>
                <div className='container'>
                    <div className="cta-three">
                        <div className="cta-three__bg jarallax" data-jarallax="" data-speed="0.3" style={{ backgroundImage: `url(${Ctaimg})` }} >
                        </div>
                        <div className="container">
                            <h2 className="cta-three__title"> 
                                Leela consultancy Trusted Staffing &amp; <br />
                                Recruitment Agency
                            </h2>
                            <div className="cta-three__btns">
                                <Link to="/about" className="btn-primary cta-three__link">
                                    <span>Discover more</span>
                                </Link>
                                <Link to="/contact" className="btn btn-white">
                                    <span>for employers</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ctathree