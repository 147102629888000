import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Faqimg1 from '../../assets/images/resources/faq-2-img.jpg'

// Import job logos
import Joblogo1 from '../../assets/images/jobs/logo-1.png';
import Joblogo2 from '../../assets/images/jobs/logo-2.png';
import Joblogo3 from '../../assets/images/jobs/logo-3.png';
import Joblogo4 from '../../assets/images/jobs/logo-4.png';
import Joblogo5 from '../../assets/images/jobs/logo-5.png';







function Careerfrom() {

    const [activeSection, setActiveSection] = useState(1);

const handleToggleSection = (sectionIndex) => {
    if (activeSection === sectionIndex) {
        setActiveSection(null);
    } else {
        setActiveSection(sectionIndex);
    }
};


    // Define an array of job objects
    const jobs = [
        { logo: Joblogo1, title: 'Data Integration Engineer', location: 'San Francisco, California' },
        { logo: Joblogo2, title: 'Administrative Coordinator', location: 'San Francisco, California' },
        { logo: Joblogo3, title: 'Human Resources Manager', location: 'San Francisco, California' },
        { logo: Joblogo4, title: 'Graphic & Web Designing', location: 'San Francisco, California' },
        { logo: Joblogo5, title: 'Digital Marketing Assistant', location: 'San Francisco, California' }
    ];

    const careerData = [
        {
            id: 1,
            title: 'Data Integration Engineer',
            content:
              'We are looking for Data Integration Engineer having 5+ year experience with best market knowledge',
            listItem: [
              { list: 'Experience in customer-facing positions as a professional services consultant or engineer.' },
              { list: 'Good Understanding of Integration Domain is a must (EAI / SOA / BPM / API / ETL).' },
              { list: 'Solid understanding of SQL and good grasp of relational and analytical database management theory and practice.' },
              { list: 'Good knowledge of JDBC, XML, JMS, Web technologies and Cloud environments.' },
              { list: 'Minimum Education: 4 Years Bachelors in Computer Science or related STEM field.' },
            ],
        },
        {
            id: 2,
            title: 'Intern - ETL Developer',
            content:
              'We are looking for an Intern - ETL Developer who has excellent market knowledge',
            listItem: [
              { list: 'Must be currently enrolled in Masters or Bachelors in Computer Science or related STEM field.' },
              { list: 'Candidate must be in his/her last year of Masters or Bachelors degree program.' },
              { list: 'Must have solid understanding of SQL, database and datawarehouse.' },
              { list: 'Excellent written and verbal communication skills; ability to clearly explain the trade-offs between possible data management solutions.' },
              { list: 'Extremely good problem solving and organizational skills.' },
              { list: 'Familiar with importing, merging and exporting data via ETL processes, CSV files, etc.' },
              
            ],
        },
        {
            id: 3,
            title: 'Informatica Programmer Analyst',
            content:
              'We are looking for Informatica Programmer Analyst having 1 year experience with best market knowledge',
            listItem: [
              { list: 'Minimum of 3 years of experience with Informatica PowerCenter or equivalent data integration software.' },
              { list: 'Development experience with Informatica Data Quality preferably v. 9.5 or higher and or experience with Master Data Management v. 10 or higher.' },
              { list: 'Development experience with relational databases especially with Oracle and SQL Server is preferred.' },
              { list: 'Knowledge and experience in software development lifecycle methodology.' },
              { list: 'Strong analytical, planning and estimating skills required.' },
              { list: 'Minimum Education: 4 Years Bachelors in Computer Science or related STEM field.' },
              
            ],
        },
        
    ];

    return (
        <>
            <div className="section-ptb">
                <div className="container">
                    <div className="sec-title text-left pb-0">
                        {/* <h6 className="sec-title__tagline">Careers</h6> */}
                        <h3 className="sec-title__title mb-3">Current career opportunities at Leela Consultancy</h3>
                        <p><strong style={{color: "#0b182a", fontSize: "16px", fontStyle: "italic"}}>Leela Consultancy is the perfect place for your career. In addition to these unique benefits we offer competitive salaries, global opportunities, and a friendly, flexible work environment.</strong></p>
                    </div>
                    <div className='careers-content mb-5'>
                        <p>In today’s fast-paced business world, companies that are faced with increasing technological change and the constant need to operate “lean” turn to Leela Consultancy to help them execute challenging IT and communications projects and deployments. We provide a wide range of technology services from talented and skilled Consultants. And that’s where you come in. There are many flexible, challenging positions available that may be perfect for your talents and career ambitions.</p>
                        <p>We achieve success for our customers by deploying the most sought after technologies, global sourcing principles and software outsourcing practices. Over the years, we have expanded our global presence ensuring that skilled resources are allocated at the right place at an optimal cost delivering the maximum output and efficiency.</p>
                        <p>We understand our success is only achieved by creating and maintaining strong, long term relationships with our talented professionals who fill the positions with our clients. Working with us, you’ll have access to the some of the best recruiters in the industry and a successful job placement process to help ensure we place you in jobs that are a perfect fit, where you can excel, develop your abilities, apply your expertise, and build a career.</p>
                        <p>Email your resume at: <Link to="mailto:careers@leelaconsultancy.com">careers@leelaconsultancy.com</Link></p>
                    </div>
                    
                    <form className="contact-form-validated" action="inc/sendemail.php" noValidate="novalidate">
                        <div className="jobs__form wow fadeInUp animated" data-wow-duration="1500ms">
                            <div className="jobs__group row gutter-y-20">
                                <div className="col-md-6">
                                    <div className="job__control Jobs__control--full">
                                        <input type="text" name="name" placeholder="All Jobs" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="jobs__control Jobs__control--full">
                                        <input type="text" name="name" placeholder="Any Location" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                    {/* <div className="jobs-list">
                        
                        {jobs.map((job, index) => (
                            <div key={index} className="jobs-list__card">
                                <div className="jobs-list__left">
                                    <div className="jobs-list__logo">
                                        <img src={job.logo} alt="" />
                                    </div>
                                    <div className="jobs-list__content">
                                        <span className="jobs-list__time">
                                            <i className="fas fa-clock" /> Posted 3 days ago
                                        </span>
                                        <h3 className="jobs-list__title">
                                            <Link to="/contact">{job.title}</Link>
                                        </h3>
                                        <span className="jobs-list__location">
                                            {job.location}
                                        </span>
                                    </div>
                                </div>
                                <div className="jobs-list__right">
                                    <span className="jobs-list__price">
                                        <b>$85,000 - $90,000</b> Per Year
                                    </span>
                                    <Link to="/contact" className="btn-primary">
                                        Apply Now
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
            <section className='section-ptb background-gray'>
                <div className='container'>
                    <div className='d-flex justify-content-center row'>
                        <div className="col-lg-10">
                            <h3>Current/Active job postings:</h3>
                             <div className="faq-page__accordion leelaconsultancy-accrodion pt-4" data-grp-name="leelaconsultancy-accrodion">
                                {careerData.map((item, index) => (
                                    <div className={`accrodion ${activeSection === index ? 'active' : 'collapsed'}`} key={index}>
                                        <div className="accrodion-title" onClick={() => handleToggleSection(index)}>
                                            <h4>
                                                {item.title}
                                                <span className="accrodion-title__icon" />
                                            </h4>
                                        </div>
                                        <div id={`collapse${index}`} className={`collapse ${activeSection === index ? 'show' : ''}`} data-bs-parent="#accordionExample" >
                                            <div className="accrodion-content">
                                                <div className="inner">
                                                    <h5>Requirements:</h5>
                                                    <p>{item.content}</p>
                                                    <ul className="list list-unstyled mt-3 mb-4">
                                                        {item.listItem.map((address, index) => (
                                                            <li key={index} className='text-secondary'>
                                                                <i className="fa fa-check-circle text-primary me-2"></i> {address.list}
                                                            </li>
                                                        ))}
                                                    </ul>

                                                    <Link to="/contact" className="btn-primary">
                                                        Apply Now
                                                    </Link>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Careerfrom;
