import React from 'react';
import { Link } from 'react-router-dom';
// import Service1 from '../../assets/images/services/sv-1.jpg';
// import Service2 from '../../assets/images/services/sv-2.jpg';
// import Service3 from '../../assets/images/services/sv-3.jpg';
// import Service4 from '../../assets/images/services/sv-4.jpg';
// import Service5 from '../../assets/images/services/sv-5.jpg';
// import Service6 from '../../assets/images/services/sv-6.jpg';

const services = [
    {
        number: "01",
        icon: "icon-consulting",
        category: "Management",
        title: "Business Integration Strategy",
        link: "/service-business-integration-strategy",
    },
    {
        number: "02",
        icon: "icon-controller",
        category: "Resources",
        title: "Information Quality Management Services",
        link: "/service-information-quality-management",
    },
    {
        number: "03",
        icon: "icon-policy",
        category: "Solution",
        title: "Data Warehousing Services",
        link: "/service-data-warehousing",
    },
    {
        number: "04",
        icon: "icon-consulting",
        category: "Management",
        title: "Master Data Management",
        link: "/service-master-data-management",
    },
    {
        number: "05",
        icon: "icon-controller",
        category: "Resources",
        title: "Analytics and Performance Management",
        link: "/service-analytics-and-performance-management",
    },
    {
        number: "06",
        icon: "icon-controller",
        category: "Resources",
        title: "App Development",
        link: "/service-app-development",
    },
    
];

function Service() {
    return (
        <div>
            <section className="service-one background-gray section-ptb">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-7 col-lg-8 col-xl-9'>
                            <div className="sec-title text-left">
                                <h6 className="sec-title__tagline">Our Services</h6>
                                <h3 className="sec-title__title">
                                    Services We’re Providing
                                </h3>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-4 col-xl-3'>
                            <div className='text-md-end text-start mb-5 mb-md-0'>
                                <Link to="/services" className="btn-primary">
                                    <span>See All Services</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row gutter-y-30">
                        {services.map((service, index) => (
                            <div key={index} className="col-xl-6 col-lg-12">
                                <div className="service-one-card wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index}ms`}>
                                    {/* <div className="service-one-card__image">
                                        <img src={service.image} alt={service.title} />
                                    </div> */}
                                        <span class="number">{service.number}</span>
                                        <div className="service-one-card__icon">
                                            <i className={service.icon} />
                                        </div>
                                        <h3 className="service-one-card__title">
                                            <Link to={service.link}>{service.title}</Link>
                                        </h3>
                                        <Link to={service.link} className="service-one-card__link">
                                            <i className="fas fa-arrow-right" />
                                        </Link>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </section>
        </div>
    );
}

export default Service;
