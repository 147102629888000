import React from 'react';
import { Link } from 'react-router-dom';
import feature1 from '../../assets/images/resources/feature-1-1.jpg';
import feature2 from '../../assets/images/resources/feature-1-2.jpg';
import feature3 from '../../assets/images/resources/feature-1-3.jpg';

const features = [
    {
        img: feature1,
        iconClass: 'icon-solution',
        title: 'Health Care',
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'Retail',
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Financial',
    },
    {
        img: feature1,
        iconClass: 'icon-solution',
        title: 'Banking',
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'Manufacturing',
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Insurance',
    }
];

function Feature() {
    return (
        <div>
            <section className="feature-one section-ptb">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="sec-title text-left">
                                <h6 className="sec-title__tagline">Sector</h6>
                                <h3 className="sec-title__title mb-3">
                                    Our Sectors
                                </h3>
                                <p className='mb-0'>Leela Consultancy serves a wide range of industries, providing specialised solutions to meet the unique possibilities and issues faced by every sector. We have expertise in manufacturing and logistics, banking, healthcare and life sciences, retail and e-commerce, telecommunications, and technology. Our mission is to enable businesses to stay competitive, improve customer satisfaction, and take benefit of fresh possibilities in the industries we serve by emphasizing reliability, compliance, and productivity.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row gutter-y-30">
                        {features.map((feature, index) => (
                            <div key={index} className="col-xl-4 col-lg-6 col-12 wow fadeInUp" data-wow-delay={`${100 * (index + 1)}ms`}>
                                <div className="feature-one__item">
                                    <div className="feature-one__item__img">
                                        <img src={feature.img} alt="leelaconsultancy" />
                                    </div>
                                    <div className="feature-one__item__content">
                                        <div className="feature-one__item__ball" />
                                        <div className="feature-one__item__icon">
                                            <span className={feature.iconClass} />
                                        </div>
                                        <h3 className="feature-one__item__title">
                                            {feature.title}
                                        </h3>
                                        {/* <p className="feature-one__item__text">
                                            {feature.text}
                                        </p>
                                        <Link to="/about" className="btn-primary">
                                            <span>Find Your Solution</span>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 text-center'>
                            <Link to="/our-sector" className="btn-primary">
                                <span>See All Sectors</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Feature;
