import React from 'react'
import Header from '../Header/Main'
import Sliderone from '../Menhomeone/Sliderone'
import Feature from '../Menhomeone/Feature'
import About from '../Menhomeone/About'
import Cta from '../Menhomeone/Cta'
import Service from '../Menhomeone/Service'
import Technologies from '../Menhomeone/Technologies'
import Testimonial from '../Menhomeone/Testimonial'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sliderone />
                <About /> 
                <Service />
                <Cta />
                <Feature />
                <Testimonial />
                <Technologies />
                <Footer />
            </div>
        </>
    )
}

export default Main