import React from 'react'
import { Link } from 'react-router-dom'
import Bgimg1 from '../../assets/images/resources/service-sidebar-d-1.png'
import Service1 from '../../assets/images/resources/service-d-1.png'
import Servicedtls from '../../assets/images/resources/service-details-p-1.png'

function EnterpriseAppDevelopment() {
    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-md-12 col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__single">
                                    <ul className="list-unstyled service-sidebar__nav">
                                        <li>
                                            <Link to="/service-business-integration-strategy">Business Integration Strategy</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-information-quality-management">Information Quality Management Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-data-warehousing">Data Warehousing Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-master-data-management">Master Data Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-analytics-and-performance-management">Analytics and Performance Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-app-development">App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-enterprise-app-development">Enterprise App Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__contact background-base" style={{ backgroundImage: `url(${Bgimg1})`}}>
                                        <h3 className="service-sidebar__contact__title"> 
                                            Recruitment Solutions for all Businesses
                                        </h3>
                                        <Link className="btn-primary" to="/contact">
                                            {" "}
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                                <div className="service-sidebar__single ">
                                    <div className="service-sidebar__download background-primary">
                                        <div className="service-sidebar__download__icon">
                                            <i className="icon-pdf-file" />
                                        </div>
                                        <div className="serice-sidebar__download">
                                            <h3 className="service-sidebar__download__title">
                                                <Link to="#">Project Agreement Form</Link>
                                            </h3>
                                            <p className="service-sidebar__download__number">3.9KB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="service-details__content">
                                <div className="service-details__thumbnail">
                                    <img src={Service1} alt="Business Integration Strategy"/>
                                </div>
                                <h3 className="service-details__title">Maximize Efficiency with Our Scalable Custom Enterprise Software Development Solutions</h3>
                                <p>
                                Expert enterprise application development company, Leela Consultancy utilizes the power of premium technology tools to provide the best solutions and services that solve your company's problems. Our team of dedicated corporate software developers focuses on creating dependable and durable digital products that meet your company's needs. Our corporate application developers provide smooth, reliable, and highly secure software by staying up with all the latest developments and trends in the field of enterprise software application development.
                                </p>
                                <p>
                                We provide our top-notch enterprise application development services to startups, government agencies, small and medium-sized companies, and enterprises who are planning to move the present-day solutions or are looking to build highly customized software.  
                                </p>
                                <h4 className="service-details_list-title">
                                    What Do We Offer?
                                </h4>
                                <h5>Custom Enterprise Software Development</h5>
                                <p>Our exceptional domain knowledge and technological proficiency allow us to develop reliable custom enterprise software that smoothly interacts with your corporate infrastructure. With our creative, scalable, agile, and customizable digital solutions, we offer you a competitive advantage.</p>
                                <h5>Enterprise Mobility Development</h5>
                                <p>Through the development of scalable, compatible, and effortlessly accessible technologies, we enable corporate mobility for enterprises. Our skilled development team is committed to providing innovative business mobility solutions with flawless operation.</p>
                                <h5>Software Integration Services</h5>
                                <p>Using the newest technology, update your software infrastructure while safeguarding your data. Our team specializes in developing enterprise solutions which can easily combine data, third-party apps, microservices, APIs, and your finished infrastructure.</p>
                                <h5>Legacy Application Modernization</h5>
                                <p>It's time to update the system you have now if it can no longer meet your evolving business requirements. Your old apps can be rebuilt by us to run on modern, affordable, adaptable, and secure technology platforms.</p>
                                <h5>Enterprise Data Management</h5>
                                <p>We assure smooth complex data management with the power of intelligent automation and predictive analytics. We build well-organized systems that quickly gather, organize, process, and display massive amounts of data.</p>

                                
                                <div className="row gutter-y-30 align-items-center">
                                    
                                    <div className="col-md-12">
                                        <h4 className="service-details_list-title mb-2">Utilize Leela Consultancy to Take Advantage of the Enterprise Application Integration Services </h4>
                                        <p>Our bespoke corporate application architecture can modernize your processes in several ways, from automating organizational activities to fostering quick customer experiences and cutting development costs and time-to-market</p>
                                        <p><strong className='text-secondary'>Efficiency as well as Increased Adaptability:</strong> Using Leela Conusltancy's sustainable enterprise application software, you can streamline business operations to make your employees’ lives easier. Reduce a lot of pressure, stay away from manual errors, and use their expertise for more important duties.</p>
                                        <p><strong className='text-secondary'>Data Management and Security:</strong> Using Agile techniques, break down boundaries and improve accessibility. Data processing is not necessary because Leela consultancy’s Enterprise application systems can securely keep and retrieve all processed information. </p>
                                        <p><strong className='text-secondary'>Planning and Conceptualizing for Businesses:</strong> Our enterprise application development services make it easier to track business performance. Let our professionals monitor everything, assess the status of your production tasks, and keep an eye on your spending.</p>
                                        <p><strong className='text-secondary'>Compliance Integration Strategy:</strong> We will assist you in checking off compliance schedules using our business software development methods. Our enterprise apps will help you maintain your data security to the highest standards in addition to assisting with record-keeping. 
                                        </p>
                                    </div>
                                </div>
                                <h4 className="service-details_list-title mb-2">How We Develop Enterprise Software?</h4>
                                <p>The phases listed below are part of the systematic approach to enterprise software development that Leela Consultancy employs:</p>
                                <h5>Discovery and Analysis</h5>
                                <p>We begin by understanding your company's needs and objectives. In close partnership with you, our team analyzes your particular needs and creates software solutions that specifically meet them.</p>
                                <h5>Design and Development</h5>
                                <p>Our team designs the software solution and starts development immediately as we figure out your needs. We provide successful, scalable, and secure software solutions utilizing the most recent techniques and technologies.</p>
                                <h5>Testing and Quality Assurance</h5>
                                <p>Considering we value quality so much, we place all of our software solutions through thorough testing in order to make sure they meet up to the highest standards. Each key has been evaluated by our team of quality assurance specialists to find any potential issues and fix them before it is released.</p>
                                <h5>Deployment and Maintenance</h5>
                                <p>We will install the software solution on the computer after it has been thoroughly verified and validated. To ensure that the software solution continues meeting your company's requirements, we offer ongoing support and maintenance services.</p>
                                
                                <h4 className="service-details_list-title mb-2">Get Professional Enterprise Software Development Services</h4>
                                <p>Our goal at Leela Consultancy is to meet your particular company requirements by providing outstanding enterprise software development services. We understand that in today's fast-paced corporate world, having the appropriate software solutions is essential to your success. To meet your needs, we provide a wide range of corporate software development solutions. </p>
                                <p>Our group of skilled software developers are knowledgeable in a wide range of databases, frameworks, and programming languages. To ensure that we give you the finest solutions accessible, our team is up to speed on the most recent innovations and technological advances. Our dedication lies in providing secure, scalable, and effective software solutions that adhere to the highest quality standards.</p>


                                <h5>Still Searching For Top Enterprise Software Development Services?</h5>
                                <Link to="/contact" className="btn-primary mt-3">
                                    <span>Connect</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EnterpriseAppDevelopment