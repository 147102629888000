import React from 'react';
import { Link } from 'react-router-dom';
import Abtimg1 from '../../assets/images/shapes/about-3-1.png';

function Abtthree() {
    const contentData = [
        {
            imgSrc: Abtimg1,
            imgAlt: 'leelaconsultancy',
            title: 'about our agency',
            subTitle: 'End-to-end Business Integration services to Create Successful Organizations',
            text: 'Our expertise at Leela Consultancy is in providing business integration and development services that enable enterprises to maximize productivity, simplify workflows, and drive growth in the future. We help businesses in maximizing the use of their resources, technology, and data to achieve their strategic goals by using our full-depth knowledge and innovative ideas.',
            list: [
                'Shorten time to market and speed the release of digital products',
                'Increase service speed and accuracy by automating',
                'With confidence, modernize, scale, and protect IT systems.',
                'Boost company expansion with data',
                'Utilize innovation to reconsider the client experience'
            ],
            callText: 'Call to Questions',
            callNumber: '+92 (003) 68-0900',
            discoverLink: '/about'
        }
    ];

    return (
        <>
            <section className="about-three section-ptb">
                <div className="container">
                    {contentData.map((item, index) => (
                        <div key={index} className="row">
                            <div className="col-lg-5">
                                <div className="about-three__image wow fadeInLeft" data-wow-delay="300ms">
                                    <img src={item.imgSrc} alt={item.imgAlt} className='img-fluid'/>
                                </div>
                            </div>
                            <div className="col-lg-7 wow fadeInRight" data-wow-delay="300ms">
                                <div className="about-three__content">
                                    <div className="sec-title text-left">
                                        <h6 className="sec-title__tagline">{item.title}</h6>
                                        <h3 className="sec-title__title">{item.subTitle}</h3>
                                    </div>
                                    <p className="about-three__content__text">{item.text}</p>
                                    <h6>With Leela Consultancy, You can:</h6>
                                    <ul className="about-three__list">
                                        {item.list.map((listItem, index) => (
                                            <li key={index}>
                                                <span className="fa fa-check-circle" />
                                                {listItem}
                                            </li>
                                        ))}
                                    </ul>
                                    {/* <div className="row">
                                        <div className="col-md-7">
                                            <Link className="about-three__call" to="tel:92003680900">
                                                <span className="about-three__call__icon">
                                                    <i className="icon-calling" />
                                                </span>
                                                <span className="about-three__call__text">
                                                    {item.callText}
                                                </span>
                                                <span className="about-three__call__number">
                                                    {item.callNumber}
                                                </span>
                                            </Link>
                                            <Link to={item.discoverLink} className="btn-primary btn-primary--base">
                                                <span>Discover more</span>
                                            </Link>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="about-three__info-box">
                                                <div className="about-three__info-box__icon">
                                                    <span className="icon-cooperation" />
                                                </div>
                                                <h3 className="about-three__info-box__title">
                                                    Trusted by Clients
                                                </h3>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>
            </section>
        </>
    );
}

export default Abtthree;
